<template>
	<div>
		<!-- 增加修改 -->
		<ant-modal :visible="open" :modal-title="formTitle" :adjust-size="true" modalHeight="480" modalWidth="640" @cancel="cancel">
			<div slot="content" layout="vertical">
				<!-- 元器件数量 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_mandatory">*</div>
						<div class="be_text">{{$t('valuation.SMT.specificationFilling.title1')}}：</div>
						<el-popover placement="bottom-start" width="400" trigger="hover"
							:content="$t('valuation.SMT.specificationFilling.content1')">
							<div slot="reference">
								<i class="el-icon-question"></i>
							</div>
						</el-popover>
					</div>
					<div style="width: 85%;">
						<div style="display: flex;">
							<input class="input1" type="text" v-model="componentsNumber" placeholder="" />
							<!-- <span class="x">PCS</span> -->
						</div>
					</div>
				</div>
				
				<!-- 电路板数量 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_mandatory">*</div>
						<div class="be_text">{{$t('valuation.SMT.specificationFilling.title2')}}：</div>
						<el-popover placement="bottom-start" width="400" trigger="hover"
							:content="$t('valuation.SMT.specificationFilling.content2')">
							<div slot="reference">
								<i class="el-icon-question"></i>
							</div>
						</el-popover>
					</div>
					<div style="width: 85%;">
						<div style="display: flex;">
							<input class="input1" type="text" v-model="circuitBoardsNumber" placeholder="" />
							<!-- <span class="x">PCS</span> -->
						</div>
					</div>
				</div>
				
				<!-- 工艺需求 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_mandatory">*</div>
						<div class="be_text">{{$t('valuation.SMT.specificationFilling.title3')}}：</div>
						<el-popover placement="bottom-start" width="400" trigger="hover"
							:content="$t('valuation.SMT.specificationFilling.content3')">
							<div slot="reference">
								<i class="el-icon-question"></i>
							</div>
						</el-popover>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="processRequirements" :dataList="dataList.SMTProcessRequirements" @select="processRequirementsSelect"></single-choice>
					</div>
				</div>
				
				<!-- 是否开钢网 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.SMT.specificationFilling.title4')}}：</div>
						<el-popover placement="bottom-start" width="400" trigger="hover"
							:content="$t('valuation.SMT.specificationFilling.content4')">
							<div slot="reference">
								<i class="el-icon-question"></i>
							</div>
						</el-popover>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="steelMeshTypeIndex" :dataList="steelMesh" @select="steelMeshTypeSelect"></single-choice>
					</div>
				</div>
				
				<!-- 代购元件 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.SMT.specificationFilling.title5')}}：</div>
						<el-popover placement="bottom-start" width="400" trigger="hover"
							:content="$t('valuation.SMT.specificationFilling.content5')">
							<div slot="reference">
								<i class="el-icon-question"></i>
							</div>
						</el-popover>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="purchaseBehalfIndex" :dataList="dataList.purchaseOnBehalf" @select="purchaseOnBehalfSelect"></single-choice>
					</div>
				</div>
				
				<!-- 是否喷三防漆 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.SMT.specificationFilling.title6')}}：</div>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="conformalCoatingIndex" :dataList="dataList.conformalCoating" @select="conformalCoatingSelect"></single-choice>
					</div>
				</div>
				
				<!-- 元器件是否烘培 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.SMT.specificationFilling.title7')}}：</div>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="componentBakingIndex" :dataList="componentBaking" @select="componentBakingSelect"></single-choice>
					</div>
				</div>
				
				<!-- 元器件烘焙时长 -->
				<div style="display: flex;padding-top: 20px;" v-if="componentBakingIndex==0">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.SMT.specificationFilling.title9')}}：</div>
					</div>
					<div style="width: 85%;">
						<single-choice :dataIndex="bakingTimeIndex" :dataList="bakingTime" @select="bakingTimeSelect"></single-choice>
					</div>
				</div>
				
				<!-- 其他需求 -->
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">{{$t('valuation.SMT.specificationFilling.title8')}}：</div>
					</div>
					<div style="width: 85%;">
						<el-input
						  type="textarea"
						  :rows="2"
						  placeholder=""
						  v-model="smtDemand">
						</el-input>
					</div>
				</div>
				
				<div style="display: flex;padding-top: 20px;">
					<div class="be_title">
						<div class="be_text">发票类型：</div>
					</div>
					<div style="width: 85%;">
						<div style="display: flex;">
							<single-choice :dataIndex="invoiceType" :dataList="dataList.invoiceType"
								@select="selectInvoiceTypeClick"></single-choice>
						</div>
					</div>
				</div>
				
				<div style="display: flex;width: 100%;justify-content: right;padding-top: 20px;" v-if="priceInfo">
					<div>预计总价：</div>
					<div style="color: red;font-weight: bold;">￥{{priceInfo || "0.00"}}</div>
				</div>
				<div style="color: red;display: flex;justify-content: right;padding-top: 10px;">*订单修改成功后，我们将根据最新的参数值重新审核订单</div>
			</div>
			<template slot="footer">
			  <a-button type="primary" @click="submitForm">
			    保存
			  </a-button>
			  <a-button @click="cancel">
			    取消
			  </a-button>
			</template>
		</ant-modal>
	</div>
</template>

<script>
	import modifyModel from './modifyModel'
	export default {
		...modifyModel
	}
</script>

<style lang="less" scoped>
	.be_title{
		display: flex;
		// align-items: center;
		width:20%;
		
		.be_mandatory{
			font-size: 1vw;
			color: red;
		}
		
		.be_text{
			color: #888;
			font-size: 0.8vw;
		}
	}
	
	.input1{
		width: 5vw;
		height: 2vw;
		line-height: 2vw;
		text-align: center;
		border: 1px solid #d6d6d6;
		background: #fff;
	}
	
	.x{
		display: inline-block;
		font-size: 0.8vw;
		border: 1px solid #d6d6d6;
		text-align: center;
		background: #efefef;
		padding: 0 10px;
		display: flex;
		align-items: center;
	}
	
	.popover{
		position: absolute;
		top:32px;
		left: 0;
		width: 400px;
		border: 1px solid #d6d6d6;
		background: #fff;
		padding: 20px;
		z-index: 2;
	}
</style>
