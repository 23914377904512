<template>
	<div>
		<!-- 增加修改 -->
		<ant-modal :visible="open" :modal-title="formTitle" :adjust-size="true" modalHeight="520" modalWidth="750"
			@cancel="cancel">
			<div slot="content" layout="vertical">
				<a-descriptions title="" size="small" bordered v-if="details">
					<a-descriptions-item label="订单编号">
						{{details.orderno}}
					</a-descriptions-item>
					<a-descriptions-item label="下单时间">
						{{filterTime(details.pcbInfoList[0].createdate)}}
					</a-descriptions-item>
					<a-descriptions-item label="元器件数量">
						{{details.pcbInfoList[0].smtcompcount}}
					</a-descriptions-item>
					<a-descriptions-item label="电路板数量">
						{{details.pcbInfoList[0].smtboardcount}}
					</a-descriptions-item>
					<a-descriptions-item label="工艺需求">
						{{dataList.SMTProcessRequirements[details.pcbInfoList[0].smttechreq-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="是否开钢网">
						{{details.pcbInfoList[0].smtissten==1?'是':'否'}}
					</a-descriptions-item>
					<a-descriptions-item label="代购元件">
						{{dataList.purchaseOnBehalf[details.pcbInfoList[0].smtagentcomp-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="是否喷三防漆">
						{{dataList.conformalCoating[details.pcbInfoList[0].smtislac-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="元器件是否需要烘烤">
						{{details.pcbInfoList[0].smtisbake==1?'是':'否'}}
					</a-descriptions-item>
					<a-descriptions-item label="元器件烘焙时长">
						{{bakingTime[details.pcbInfoList[0].smtbakedate-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="其他需求" :span="2">
						
					</a-descriptions-item>
					<a-descriptions-item label="发票类型" :span="1">
						{{dataList.invoicingMethod[details.invoicetype-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="下单联系人" :span="1">
						{{details.ordername}}
					</a-descriptions-item>
					<a-descriptions-item label="联系人手机" :span="1">
						{{details.orderphone}}
					</a-descriptions-item>
					<a-descriptions-item label="快递类型" :span="1">
						{{details.delivertype}}
					</a-descriptions-item>
					<a-descriptions-item label="快递费用" :span="2">
						{{details.deliverprice}}
					</a-descriptions-item>
					<a-descriptions-item label="收货人地址" :span="3">
						{{details.address}}
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions title="费用明细" size="small" bordered v-if="details">
					<a-descriptions-item label="合计">
						{{details.smtfee}}
					</a-descriptions-item>
				</a-descriptions>
			</div>
			<template slot="footer">
				<!-- <a-button type="primary" @click="submitForm">
					保存
				</a-button> -->
				<a-button @click="cancel">
					取消
				</a-button>
			</template>
		</ant-modal>
	</div>
</template>

<script>
	import AntModal from '@/components/pt/dialog/AntModal' //Modal组件引入
	import moment from 'moment'
	import {
		dataInJs
	} from '@/business/valuation/components/data.js';
	export default {
		components: {
			AntModal
		},
		props: {
			open: { // 按钮默认文本
				type: Boolean,
				default: false
			},
			details: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				modalOpen: this.open, //弹窗打开关闭
				formTitle: "修改产品", //弹框名
				customStyle: 'background: #fff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden;',
				dataList: dataInJs(), //字典list数据
				bakingTime:[{
					name:'12小时',
					enName:"12 Hour",
					type:true
				},
				{
					name:'24小时',
					enName:"24 Hour",
					type:true
				},
				{
					name:'48小时',
					enName:"48 Hour",
					type:true
				}],
			}
		},

		created() {

		},

		mounted() {

		},

		methods: {
			//------------------------取消按钮-------------------------------
			cancel: function() {
				// console.log("cancel")
				this.modalOpen = false;
				this.$emit('close');
			},
			
			//------------------时间戳翻译-----------------------
			filterTime(time) {
				return moment(time).format("YYYY-MM-DD HH:mm:ss")
			},

			//------------------------提交按钮--------------------------------
			submitForm: function() {
				this.$refs.form.validate(valid => {
					if (valid) {
						this.$message.success(
							'修改成功',
						)
						this.open = false
						this.$emit('ok')
					} else {
						return false
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>

</style>