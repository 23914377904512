import AntModal from '@/components/pt/dialog/AntModal' //Modal组件引入
import CButton from '@/components/CButton/index.vue' //button组件引入
import singleChoice from '@/components/singleChoice/index.vue';
import changeSingleChoice from '@/components/changeSingleChoice/index.vue';
import singleChoiceImage from '@/components/singleChoiceImage/index.vue';
import singleChoiceColor from '@/components/singleChoiceColor/index.vue';
import singleChoicePopup from '@/components/singleChoicePopup/index.vue';
import {
	calcPcbComponentlnfoPrice
} from '@/api/home/index.js'
import {
	updateOrder
} from '@/api/orderManagement/index'
import {
	dataInJs
} from '@/business/valuation/components/data.js';
export default {
	components: {
		singleChoice,
		changeSingleChoice,
		singleChoiceImage,
		singleChoiceColor,
		singleChoicePopup,
		AntModal,
		CButton
	},
	props: {
		open: { // 按钮默认文本
			type: Boolean,
			default: false
		},
		dataInfo: {
			type: Object,
			default: () => {}
		},
	},
	data() {
		return {
			dataList: dataInJs(), //list数据
			modalOpen: this.open, //弹窗打开关闭
			formTitle: "修改产品", //弹框名
			customStyle: 'background: #fff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden;',
			smtDemand: null, //其他需求
			componentsNumber: null, //元器件数量
			circuitBoardsNumber: null, //电路板数量
			processRequirements: 0, //工艺需求
			steelMeshTypeIndex: 0, //是否开钢网
			purchaseBehalfIndex: 0, //代购元件
			conformalCoatingIndex: 0, //是否喷三防漆
			componentBakingIndex: 1, //元器件是否烘培
			bakingTimeIndex: 0, //烘培时间
			boardNumberPliesIndex: 0, //板子层数
			invoiceType: 0, //发票类型下标
			steelMesh: [{
				name: '是',
				enName: "Yes",
				type: true
			}],
			componentBaking: [{
					name: '是',
					enName: "Yes",
					type: true
				},
				{
					name: '否',
					enName: "Not",
					type: true
				}
			],
			bakingTime: [{
					name: '12小时',
					enName: "12 Hour",
					type: true
				},
				{
					name: '24小时',
					enName: "24 Hour",
					type: true
				},
				{
					name: '48小时',
					enName: "48 Hour",
					type: true
				}
			],
			priceInfo: null, //订单价格
		}
	},

	created() {

	},

	mounted() {

	},

	watch: {
		open(val) {
			// console.log(this.dataInfo)
			this.componentsNumber = this.dataInfo.pcbInfoList[0].smtcompcount
			this.circuitBoardsNumber = this.dataInfo.pcbInfoList[0].smtboardcount
			this.processRequirements = this.dataInfo.pcbInfoList[0].smttechreq - 1
			this.steelMeshTypeIndex = this.dataInfo.pcbInfoList[0].smtissten - 1
			this.purchaseBehalfIndex = this.dataInfo.pcbInfoList[0].smtagentcomp - 1
			this.conformalCoatingIndex = this.dataInfo.pcbInfoList[0].smtislac - 1
			this.componentBakingIndex = this.dataInfo.pcbInfoList[0].smtisbake - 1
			this.bakingTimeIndex = this.dataInfo.pcbInfoList[0].smtbakedate - 1
			this.smtDemand = this.dataInfo.pcbInfoList[0].smtmark
			this.invoiceType = this.dataInfo.invoicetype - 1
			this.calcPcbComponentlnfoPrice()
		},
		componentsNumber(val){
			this.calcPcbComponentlnfoPrice()
		},
		circuitBoardsNumber(val){
			this.calcPcbComponentlnfoPrice()
		}
	},

	computed: {

	},

	methods: {
		//------------------------取消按钮-------------------------------
		cancel: function() {
			// console.log("cancel")
			this.modalOpen = false;
			this.$emit('close');
		},

		//-------------工艺需求----------------------
		processRequirementsSelect: function(index) {
			this.processRequirements = index;
			this.calcPcbComponentlnfoPrice()
		},

		//------------是否开钢网回调-------------------
		steelMeshTypeSelect: function(index) {
			this.steelMeshTypeIndex = index;
			this.calcPcbComponentlnfoPrice()
		},

		//--------------代购元件回调--------------------
		purchaseOnBehalfSelect: function(index) {
			this.purchaseBehalfIndex = index;
			this.calcPcbComponentlnfoPrice()
		},

		//---------------是否喷三防漆回调--------------------
		conformalCoatingSelect: function(index) {
			this.conformalCoatingIndex = index;
			this.calcPcbComponentlnfoPrice()
		},

		//---------------元器件是否烘焙------------------------
		componentBakingSelect: function(index) {
			this.componentBakingIndex = index
			this.calcPcbComponentlnfoPrice()
		},

		//---------------元器件是否烘焙------------------------
		bakingTimeSelect: function(index) {
			this.bakingTimeIndex = index
			this.calcPcbComponentlnfoPrice()
		},

		//--------------------------发票类型单选回调-----------------------------
		selectInvoiceTypeClick: function(e) {
			this.invoiceType = e;
			this.calcPcbComponentlnfoPrice()
		},

		//------------------------调用计算价格接口-------------------------
		calcPcbComponentlnfoPrice: function() {
			let data = {
				smtcompcount: Number(this.componentsNumber),
				smtboardcount: Number(this.circuitBoardsNumber),
				smttechreq: this.processRequirements + 1,
				smtissten: this.steelMeshTypeIndex + 1,
				smtagentcomp: this.purchaseBehalfIndex + 1,
				smtislac: this.conformalCoatingIndex + 1,
				smtisbake: this.componentBakingIndex + 1,
				smtbakedate: this.bakingTimeIndex + 1,
				smtsenddate: this.dataInfo.pcbInfoList[0].smtsenddate,
				smtmark: this.smtDemand,
				// pcbcount:1
			}
			calcPcbComponentlnfoPrice(data).then(res => {
				// console.log("calcPcbComponentlnfoPrice", res)
				this.priceInfo = res.data;
			})
		},

		//------------------------提交按钮--------------------------------
		submitForm: function() {
			this.$confirm('是否保存修改的订单？', '修改订单', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					pcbInfo: [{
						id: this.dataInfo.pcbInfoList[0].id,
						smtcompcount: Number(this.componentsNumber),
						smtboardcount: Number(this.circuitBoardsNumber),
						smttechreq: this.processRequirements + 1,
						smtissten: this.steelMeshTypeIndex + 1,
						smtagentcomp: this.purchaseBehalfIndex + 1,
						smtislac: this.conformalCoatingIndex + 1,
						smtisbake: this.componentBakingIndex + 1,
						smtbakedate: this.bakingTimeIndex + 1,
						smtmark: this.smtDemand,
					}],
					orderInfo: {
						invoicetype: this.invoiceType + 1,
						id: this.dataInfo.id,
						smtfee: this.priceInfo,
						// orderstatus: 0
					}
				}
				updateOrder(data).then(res => {
					this.$message.success(
						'修改成功',
					)
					this.modalOpen = false
					this.$emit('ok')
				})
			}).catch(() => {});
		},
	}
}